<template>
  <div v-if="pending > 0" class="loader-overlay">
    <div class="lds-ripple">
      <div></div>
      <div></div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('loader', [
      'pending',
    ]),
  },
};
</script>
<style lang="scss" scoped>
.loader-overlay {
  background: linear-gradient(45deg, rgba(#fff,.2), rgba(#fff,.5));
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fd1d1d;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
  border-color: #fcb045;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

</style>
