<template>
  <div id="app" class="app" :class="{'is-login': $router.currentRoute.name === 'Login'}">
    <section class="sidebar">
      <h1><span>Prewarm.it</span></h1>
      <nav id="nav">
        <router-link to="/sessions"><mdicon name="fire" /> Sessions</router-link>
        <router-link to="/projects"><mdicon name="cube" /> Projets</router-link>
        <router-link to="/account"><mdicon name="account" /> Mon compte</router-link>
      </nav>
      <div class="sidebar-footer">
        <amplify-sign-out></amplify-sign-out>
      </div>
    </section>
    <section class="content">
      <router-view/>
    </section>
    <loader />
  </div>
</template>
<script>
import Loader from '@/components/Loader.vue';

export default {
  components: { Loader },
};
</script>
<style lang="scss" scoped>
@import './styles/mixins.scss';

#app {
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-columns: 15rem 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    "sidebar  content"
}
#app.is-login {
  grid-template-columns: 1fr;
  grid-template-areas: 'content';

  .sidebar {
    display: none;
  }
  h1 {
    text-align: center;
    font-size: 2rem;
  }
}

.sidebar {
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  // align-items: center;
  nav {
    flex-grow: 1;
  }
}

.sidebar-footer {
  text-align: center;
}

.content {
  grid-area: content;
  padding: 2rem;
  background: linear-gradient(135deg, #f0f0f0, #fefefe);
  overflow: auto;
}

#nav a {
  display: block;
  margin: 1rem;
  padding: 1rem 2rem;
  color: #999;
  font-weight: 400;
  text-decoration: none;
  border-radius: 1rem;

  &.router-link-active, &.router-link-active:hover {
    // background: linear-gradient(135deg, #eee, #f0f0f0);
    background: linear-gradient(135deg, #833ab4, #fd1d1d, #fcb045);
    color: #fff;
    font-weight: 600;
    box-shadow: 0 3px 10px rgba(#000, .2);
  }

  &:hover {
    background: linear-gradient(135deg, #f5f5f5, #fafafa);
  }
}

h1 {
  padding: 1rem 1.9em;
  color: orange;
  span{
    @include text-gradient(linear-gradient(135deg,#833ab4, #fd1d1d, #fcb045));
    font-weight: 100;
    font-size: 1em;
  }
  // margin-bottom: 5em;
}
</style>
