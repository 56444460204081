<template>
  <div class="view-login">
    <amplify-authenticator></amplify-authenticator>
  </div>
</template>
<script>
import { AmplifyEventBus } from 'aws-amplify-vue';

export default {
  data() {
    return {};
  },
  mounted() {
    AmplifyEventBus.$on('authState', (eventInfo) => {
      if (eventInfo === 'signedIn') {
        this.$router.push({ name: 'Home' });
      } else if (eventInfo === 'signedOut') {
        this.$router.push({ name: 'Login' });
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.view-login {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
