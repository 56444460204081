import API from '@aws-amplify/api';

const interceptors = [];

async function dispatch(event) {
  for (let i = 0; i < interceptors.length; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const r = await interceptors[i](event);
    if (r === false) {
      return false;
    }
  }
  return true;
}

async function wrap(promise) {
  if (!(await dispatch({ event: 'request' }))) return null;
  return promise.then(
    async (r) => {
      if (!(await dispatch({ event: 'response' }))) return null;
      return r;
    },
    async (err) => {
      if (!(await dispatch({ event: 'error' }))) return null;
      return err;
    },
  );
}

export default {
  intercept(handler) {
    interceptors.push(handler);
  },
  async get(path, opts) {
    return wrap(API.get('api', path, opts));
  },
  async post(path, opts) {
    return wrap(API.post('api', path, opts));
  },
  async delete(path, opts) {
    return wrap(API.del('api', path, opts));
  },
};
