import Vue from 'vue';
import Vuex from 'vuex';

import account from './account';
import projects from './projects';
import sessions from './sessions';
import loader from './loader';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    account,
    projects,
    sessions,
    loader,
  },
});

export default store;
