import Vue from 'vue';
import mdiVue from 'mdi-vue';
import { Logger, I18n } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import Api from '@aws-amplify/api';

import { AmplifyPlugin, AmplifyEventBus } from 'aws-amplify-vue';
import aws from '@/aws-exports';
import api from '@/lib/api';
import App from './App.vue';
import router from './router';
import store from './store';
import icons from './icons';

import './styles/main.scss';

// Amplify.configure(aws);
Auth.configure(aws);
Api.configure(aws);

Vue.config.productionTip = false;
Vue.use(mdiVue, {
  icons,
});
Vue.use(AmplifyPlugin, {
  Auth,
  Logger,
  I18n,
});

api.intercept(({ event }) => {
  switch (event) {
    case 'request':
      store.commit('loader/add');
      break;
    case 'response':
    case 'error':
      store.commit('loader/remove');
      break;
    default:
  }
});

I18n.setLanguage('fr');
I18n.putVocabulariesForLanguage('fr', {
  'Sign Out': 'Déconnexion',
  'Sign in to your account': 'Connectez-vous',
  'Sign In': 'Se connecter',
  Username: 'Email',
  Password: 'Mot de passe',

});
AmplifyEventBus.$on('authState', (info) => {
  switch (info) {
    case 'signedOut':
      router.push({ name: 'Login' });
      break;
    case 'signedIn':
      break;
    default:
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
