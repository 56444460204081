import {
  // Nav
  mdiHome,
  mdiCube,
  mdiFire,
  mdiAccount,

  // Button
  mdiReload,
  mdiPlus,

  // Agent
  mdiMonitor,
  mdiTablet,
  mdiCellphone,
  mdiRobotVacuumVariant,
  mdiRobotHappyOutline,
  mdiExclamation,
  mdiAsterisk,

  // Status
  mdiPauseCircleOutline,
  mdiCircleSlice1,
  mdiCircleSlice2,
  mdiCircleSlice3,
  mdiCircleSlice5,
  mdiCheckboxMarkedCircleOutline,
  mdiCancel,
  mdiCheckboxBlankCircle,

  // Trigger
  mdiGestureTap,
  mdiClockOutline,

  // Misc
  mdiFileOutline,
} from '@mdi/js';

export default {
  // Nav
  mdiHome,
  mdiCube,
  mdiFire,
  mdiAccount,

  // Button
  mdiReload,
  mdiPlus,

  // Agent
  mdiMonitor,
  mdiTablet,
  mdiCellphone,
  mdiRobotVacuumVariant,
  mdiRobotHappyOutline,
  mdiExclamation,
  mdiAsterisk,

  // Status
  mdiPauseCircleOutline,
  mdiCircleSlice1,
  mdiCircleSlice2,
  mdiCircleSlice3,
  mdiCircleSlice5,
  mdiCheckboxMarkedCircleOutline,
  mdiCancel,
  mdiCheckboxBlankCircle,

  // Trigger
  mdiGestureTap,
  mdiClockOutline,

  // Misc
  mdiFileOutline,
};
