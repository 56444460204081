/* eslint-disable no-shadow */
import api from '@/lib/api';

const state = {
  projects: [],
  project: {
    userSettings: {},
  },
  $dirty: {
    projects: true,
    project: true,
  },
};
const mutations = {
  setDirty(state, key) {
    state.$dirty[key] = true;
  },
  setProjects(state, projects) {
    state.projects = projects;
    state.$dirty.projects = false;
  },
  setProject(state, project) {
    state.project = project || {
      userSettings: {
        scheduleMode: 'daily',
        warmingCurveMode: 'normal',
        bandwithSaverMode: 'off',
        agents: ['desktop'],
      },
    };
    state.project.userSettings.agents = state.project.userSettings.agents || [];
    state.$dirty.project = false;
  },
};

const actions = {
  async loadProjects({ commit, state }, force = false) {
    if (state.$dirty.projects || force) {
      const res = await api.get('/projects?test=oo');
      commit('setProjects', res);
    }
    return state.projects;
  },
  async useProject({ commit, state }, id) {
    if (state.$dirty.project || id !== state.project.id) {
      commit('setProject', null);
      if (id) {
        const res = await api.get(`/projects/${id}`);
        commit('setProject', res);
      } else {
        commit('setProject');
      }
    }
  },

  async saveProject({ commit, state }) {
    const { project } = state;

    const res = await api.post('/projects', { body: project });
    commit('setProject', res);
    commit('setDirty', 'projects');
    commit('setDirty', 'project');
  },
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
