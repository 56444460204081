/* eslint-disable no-shadow */
import api from '@/lib/api';

const state = {
  id: null,
};
const mutations = {
  setAccount(state, account) {
    state.id = account.id;
  },
};

const actions = {
  async load({ commit }) {
    const res = await api.get('/account');
    commit('setAccount', res);
  },
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
