/* eslint-disable no-shadow */
import dayjs from 'dayjs';
import api from '@/lib/api';

function buildName(session) {
  if (!session) return '';
  const project = session.project || {};
  return `${project.name}/${dayjs(session.date).format('YYYY-MM-DD/HHmmss')}`;
}

const state = {
  sessions: [],
  sessionsMetrics: {

  },
  session: {

  },
  $dirty: {
    sessions: true,
    session: true,
  },
};
const mutations = {
  setDirty(state, key) {
    state.$dirty[key] = true;
  },
  setSessions(state, sessions) {
    state.sessions = (sessions || []).map((s) => ({
      ...s,
      name: buildName(s),
    }));
    state.$dirty.sessions = false;
  },
  setSessionsMetrics(state, metrics) {
    state.sessionsMetrics = metrics;
  },
  setSession(state, session) {
    if (session) {
      // eslint-disable-next-line no-param-reassign
      session.name = buildName(session);
    }
    if (session && session.metricsDetails && session.metricsDetails.details) {
      // eslint-disable-next-line no-param-reassign
      session.metricsDetails.details = session.metricsDetails.details
        .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
    }
    state.session = session;
    state.$dirty.session = false;
  },
};

const actions = {
  async loadSessions({ commit, state, dispatch }, force = false) {
    if (state.$dirty.sessions || force) {
      const [projects, sessions, metrics] = await Promise.all([
        await dispatch('projects/loadProjects', {}, { root: true }),
        await api.get('/sessions'),
        await api.get('/sessions/metrics'),
      ]);
      commit('setSessions', sessions.map((s) => ({
        ...s,
        project: projects.find((p) => p.id === s.projectId),
      })));
      commit('setSessionsMetrics', metrics);
    }
  },
  async useSession({ commit, state, dispatch }, id) {
    if (state.$dirty.session || id !== state.session.id) {
      commit('setSession', null);
      if (id) {
        const [projects, sess, metrics] = await Promise.all([
          await dispatch('projects/loadProjects', {}, { root: true }),
          await api.get(`/sessions/${id}`),
          await api.get(`/sessions/${id}/metrics`),
        ]);

        commit('setSession', {
          ...sess,
          metricsDetails: metrics || [],
          project: projects.find((p) => p.id === sess.projectId),
        });
      } else {
        commit('setSession');
      }
    }
  },

  async refreshSession({ commit, state }) {
    if (!state.session) {
      return;
    }
    const [sess, metrics] = await Promise.all([
      await api.get(`/sessions/${state.session.id}`),
      await api.get(`/sessions/${state.session.id}/metrics`),
    ]);

    commit('setSession', {
      ...state.session,
      ...sess,
      metricsDetails: metrics || [],
    });
  },

  async startSession({ commit }, {
    projectId,
    sandbox,
    agents,
    warmingCurveMode,
    bandwithSaverMode,
  }) {
    if (!projectId) {
      console.error('Unable to start session, project not defined');
      return null;
    }

    const session = await api.post('/sessions', {
      body: {
        projectId,
        sandbox,
        agents,
        warmingCurveMode,
        bandwithSaverMode,
      },
    });
    commit('setDirty', 'sessions');
    commit('setDirty', 'session');
    return session.id;
  },

  async abortSession({ commit }, sessionId) {
    if (!sessionId) {
      console.error('Unable to start session, project not defined');
      return;
    }
    await api.delete(`/sessions/${sessionId}`);
    commit('setDirty', 'sessions');
    commit('setDirty', 'session');
  },

  // async downloadReport(_, sessionId) {
  //   await api.get(`/sessions/${state.session.id}`);
  // },
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
