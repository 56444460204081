/* eslint-disable no-shadow */
const state = {
  pending: 0,
};
const mutations = {
  add(state) {
    state.pending += 1;
  },
  remove(state) {
    state.pending -= 1;
  },
};

const actions = {

};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
