import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../views/Dashboard.vue';
import Login from '../views/Login.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/sessions',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false, isLogin: true },
  },
  {
    path: '/home',
    name: 'Home',
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('../views/Projects.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/projects/new',
    name: 'ProjectNew',
    component: () => import('../views/ProjectNew.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/projects/:id',
    component: () => import('../views/Project.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'view',
        name: 'Project',
        component: () => import('../views/ProjectView.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'edit',
        name: 'ProjectEdit',
        component: () => import('../views/ProjectForm.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'api',
        name: 'ProjectApi',
        component: () => import('../views/ProjectForm.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },

  {
    path: '/sessions',
    name: 'Sessions',
    component: () => import('../views/Sessions.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/sessions/start',
    name: 'SessionStart',
    component: () => import('../views/SessionStart.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/sessions/:id',
    name: 'Session',
    component: () => import('../views/Session.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/billing',
    name: 'Billing',
    component: () => import('../views/Billing.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue'),
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some((rec) => rec.meta.requiresAuth)) {
    try {
      await Vue.prototype.$Amplify.Auth.currentAuthenticatedUser();
      next();
    } catch (e) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      });
    }
  }
  next();
});

export default router;
